import * as React from 'react';
export class InPersonLayout extends React.Component {
    render() {
        const { Map, SearchControls, FilterPane, SelectedLocationPane, ResultListPane, selectedLocation, onClearSelectedLocation, children } = this.props;
        const { displayFilters } = this.state || {};
        const shouldDisplayFilters = displayFilters && !selectedLocation;
        const shouldDisplayList = !shouldDisplayFilters && !selectedLocation;
        return (React.createElement("div", { className: `location-map ${selectedLocation ? 'location-selected' : 'no-location-selected'}` },
            React.createElement("div", { className: `location-map__content` },
                React.createElement("div", { className: `location-map__pane location-map__pane-filters ${shouldDisplayFilters ? '' : 'hidden'}` },
                    React.createElement(FilterPane, { displayFilters: displayFilters, onFilterToggle: () => this.setState({ displayFilters: !displayFilters }) })),
                selectedLocation &&
                    React.createElement("div", { className: `location-map__pane location-map__pane-location` },
                        React.createElement("div", { className: "location-map__pane__controls" },
                            React.createElement("a", { className: "text-button", onClick: onClearSelectedLocation },
                                React.createElement("i", { className: "material-icons" }, "keyboard_backspace"),
                                " Back to All Locations")),
                        React.createElement(SelectedLocationPane, null)),
                React.createElement("div", { className: `location-map__pane location-map__pane-list ${shouldDisplayList ? '' : 'hidden'}` },
                    React.createElement(SearchControls, { displayFilters: displayFilters, onFilterToggle: () => this.setState({ displayFilters: !displayFilters }) }),
                    React.createElement(ResultListPane, null)),
                children),
            React.createElement("div", { className: `location-map__map` },
                React.createElement(Map, null))));
    }
}
