import * as React from 'react';
import FilterPane from './filter-pane';
import MapPane from './map-pane';
import VirtualLocationDetailPane from './virtual-location-detail-pane';
import LocationDetailPane from './location-detail-pane';
import ListPane from './list-pane';
import SearchControls from './search-controls';
export const LocationSearchResults = ({ searchAsYouType, Layout, ListItem, Marker, translate, google, algoliaAnalytics }) => {
    const [includeUserLocationInBounds, setIncludeUserLocationInBounds] = React.useState(true);
    const [selectedLocation, setSelectedLocation] = React.useState();
    const setLocation = React.useCallback((location) => {
        if (location && selectedLocation &&
            location.objectID == selectedLocation.objectID) {
            // They may be trying to double-click the marker to zoom in further.
            // Reset the currentPlace so we don't limit the bounds
            setIncludeUserLocationInBounds(false);
            return;
        }
        if (algoliaAnalytics && algoliaAnalytics.aa && algoliaAnalytics.sendAnalytics && location) {
            const hitInternal = location;
            algoliaAnalytics.aa('clickedObjectIDsAfterSearch', {
                index: algoliaAnalytics.indexName,
                eventName: 'clickLocation',
                queryID: hitInternal.__queryID,
                objectIDs: [hitInternal.objectID],
                positions: [hitInternal.__position]
            });
        }
        setIncludeUserLocationInBounds(true);
        setSelectedLocation(location || undefined);
    }, [selectedLocation]);
    return React.createElement(Layout, { selectedLocation: selectedLocation, onClearSelectedLocation: () => setLocation(null), 
        // useCallback preserves the SearchControls and FilterPane so it doesn't
        // unmount and delete the current filter state whenever we rerender.
        SearchControls: React.useCallback((props) => React.createElement(SearchControls, Object.assign({ searchAsYouType: searchAsYouType }, props, { google: google })), [searchAsYouType, google]), FilterPane: React.useCallback(({ onFilterToggle }) => React.createElement(FilterPane, { translate: translate, onFilterToggle: onFilterToggle }), [translate]), SelectedLocationPane: React.useCallback(() => {
            if (!selectedLocation) {
                return null;
            }
            if (selectedLocation.isVirtual) {
                return React.createElement(VirtualLocationDetailPane, { translate: translate, selectedLocation: selectedLocation });
            }
            return React.createElement(LocationDetailPane, { translate: translate, selectedLocation: selectedLocation });
        }, [selectedLocation, translate]), ResultListPane: React.useCallback(() => React.createElement(ListPane, { google: google, onLocationSelect: setLocation, selectedLocation: selectedLocation, translate: translate, ListItem: ListItem }), [google, setLocation, selectedLocation, translate, ListItem]), Map: React.useCallback(() => React.createElement(MapPane, { google: google, loaded: true, selectedLocation: selectedLocation && !selectedLocation.isVirtual ? selectedLocation : undefined, includeUserLocationInBounds: includeUserLocationInBounds, onLocationSelect: setLocation, Marker: Marker }), [google, selectedLocation, includeUserLocationInBounds, setLocation, Marker]) });
};
