import * as React from 'react';
import { Configure } from 'react-instantsearch-dom';
import { AlgoliaSearch } from '@watermarkchurch/react-instantsearch-components';
import searchInsights from 'search-insights';
import { LocationSearchResults } from './location-search-results';
import { TabNavItem, TabPane } from './bootstrap/tabs';
import SearchConfig from './search-config';
import { DefaultLayouts } from './layouts';
export { DefaultLocationMarker } from './map-pane';
export { DefaultListItem, calculateDistanceText } from './list-pane/list-item';
export * from './utils';
export * from './types';
export { parseTzlessDate } from './utils/parseTzlessDate';
export default class LocationMap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: props.includeVirtualEvents ? 'in-person' : undefined
        };
        this.aa = props.aa;
    }
    componentDidMount() {
        // Initialize searchInsights if not injected
        if (!this.aa) {
            searchInsights('init', {
                appId: this.props.algoliaAppId,
                apiKey: this.props.algoliaApiKey
            });
            this.aa = searchInsights;
        }
    }
    render() {
        const { algoliaAppId, algoliaApiKey, indexName, virtualEventsIndex, includeVirtualEvents, domainObject, aa, ...rest } = this.props;
        // Analytics are on by default
        const sendAnalytics = this.props.sendAnalytics !== false;
        const algoliaAnalytics = {
            indexName,
            aa: this.aa,
            sendAnalytics
        };
        const extraConfig = {
            clickAnalytics: sendAnalytics,
            analytics: sendAnalytics
        };
        if (!this.props.includeVirtualEvents) {
            return React.createElement(AlgoliaSearch, { apiKey: algoliaApiKey, appId: algoliaAppId, indexName: indexName, widgetName: 'location-map-widget' },
                React.createElement(SearchConfig, { domainObject: domainObject }),
                React.createElement(LocationSearchResults, Object.assign({}, rest, { Layout: DefaultLayouts['in-person'], algoliaAnalytics: algoliaAnalytics })),
                React.createElement(Configure, Object.assign({}, extraConfig)));
        }
        const { translate } = this.props;
        const { activeTab } = this.state;
        return React.createElement(AlgoliaSearch, { apiKey: algoliaApiKey, appId: algoliaAppId, 
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            indexName: activeTab == 'virtual' ? this.props.virtualEventsIndex : this.props.indexName, widgetName: 'location-map-widget' },
            React.createElement(SearchConfig, { domainObject: domainObject }),
            React.createElement("div", { className: `row tab-header` },
                React.createElement(TabNavItem, { id: "in-person", bookmark: translate('locationMap.tabs.in_person.bookmark'), active: activeTab == 'in-person', translations: {
                        header: translate('locationMap.tabs.in_person.header')
                    }, storeState: true, onTabSelected: (id) => this.setState({ activeTab: id }) }),
                React.createElement(TabNavItem, { id: "virtual", bookmark: translate('locationMap.tabs.virtual.bookmark'), active: activeTab == 'virtual', translations: {
                        header: translate('locationMap.tabs.virtual.header')
                    }, storeState: true, onTabSelected: (id) => this.setState({ activeTab: id }) })),
            React.createElement("div", { className: `row tab-content` },
                React.createElement(TabPane, { id: "in-person", active: activeTab == 'in-person' }, activeTab == 'in-person' &&
                    React.createElement(LocationSearchResults, Object.assign({}, rest, { Layout: DefaultLayouts['in-person'], algoliaAnalytics: algoliaAnalytics }))),
                React.createElement(TabPane, { id: "virtual", active: activeTab == 'virtual' }, activeTab == 'virtual' &&
                    React.createElement(LocationSearchResults, Object.assign({}, rest, { Layout: DefaultLayouts['virtual'], algoliaAnalytics: algoliaAnalytics })))),
            React.createElement(Configure, Object.assign({}, extraConfig)));
    }
}
